exports.components = {
  "component---src-components-templates-blog-blog-by-author-jsx": () => import("./../../../src/components/templates/blog/blog-by-author.jsx" /* webpackChunkName: "component---src-components-templates-blog-blog-by-author-jsx" */),
  "component---src-components-templates-blog-blog-by-topic-jsx": () => import("./../../../src/components/templates/blog/blog-by-topic.jsx" /* webpackChunkName: "component---src-components-templates-blog-blog-by-topic-jsx" */),
  "component---src-components-templates-blog-blog-post-jsx": () => import("./../../../src/components/templates/blog/blog-post.jsx" /* webpackChunkName: "component---src-components-templates-blog-blog-post-jsx" */),
  "component---src-components-templates-job-job-jsx": () => import("./../../../src/components/templates/job/job.jsx" /* webpackChunkName: "component---src-components-templates-job-job-jsx" */),
  "component---src-components-templates-mdx-blog-mdx-blog-post-jsx-content-file-path-src-blog-2024-basics-of-graphql-index-mdx": () => import("./../../../src/components/templates/mdx-blog/mdx-blog-post.jsx?__contentFilePath=/opt/build/repo/src/blog/2024/basics-of-graphql/index.mdx" /* webpackChunkName: "component---src-components-templates-mdx-blog-mdx-blog-post-jsx-content-file-path-src-blog-2024-basics-of-graphql-index-mdx" */),
  "component---src-components-templates-mdx-blog-mdx-blog-post-jsx-content-file-path-src-blog-2024-benchmark-of-nodejs-validators-index-mdx": () => import("./../../../src/components/templates/mdx-blog/mdx-blog-post.jsx?__contentFilePath=/opt/build/repo/src/blog/2024/benchmark-of-nodejs-validators/index.mdx" /* webpackChunkName: "component---src-components-templates-mdx-blog-mdx-blog-post-jsx-content-file-path-src-blog-2024-benchmark-of-nodejs-validators-index-mdx" */),
  "component---src-components-templates-mdx-blog-mdx-blog-post-jsx-content-file-path-src-blog-2024-e-mobility-revolution-v-2-g-index-mdx": () => import("./../../../src/components/templates/mdx-blog/mdx-blog-post.jsx?__contentFilePath=/opt/build/repo/src/blog/2024/e-mobility-revolution-v2g/index.mdx" /* webpackChunkName: "component---src-components-templates-mdx-blog-mdx-blog-post-jsx-content-file-path-src-blog-2024-e-mobility-revolution-v-2-g-index-mdx" */),
  "component---src-components-templates-mdx-blog-mdx-blog-post-jsx-content-file-path-src-blog-2024-introduction-to-accessibility-index-mdx": () => import("./../../../src/components/templates/mdx-blog/mdx-blog-post.jsx?__contentFilePath=/opt/build/repo/src/blog/2024/introduction-to-accessibility/index.mdx" /* webpackChunkName: "component---src-components-templates-mdx-blog-mdx-blog-post-jsx-content-file-path-src-blog-2024-introduction-to-accessibility-index-mdx" */),
  "component---src-components-templates-mdx-blog-mdx-blog-post-jsx-content-file-path-src-blog-2024-live-feature-flags-with-aws-index-mdx": () => import("./../../../src/components/templates/mdx-blog/mdx-blog-post.jsx?__contentFilePath=/opt/build/repo/src/blog/2024/live-feature-flags-with-aws/index.mdx" /* webpackChunkName: "component---src-components-templates-mdx-blog-mdx-blog-post-jsx-content-file-path-src-blog-2024-live-feature-flags-with-aws-index-mdx" */),
  "component---src-components-templates-mdx-blog-mdx-blog-post-jsx-content-file-path-src-blog-2024-microfrontends-architecture-overview-index-mdx": () => import("./../../../src/components/templates/mdx-blog/mdx-blog-post.jsx?__contentFilePath=/opt/build/repo/src/blog/2024/microfrontends-architecture-overview/index.mdx" /* webpackChunkName: "component---src-components-templates-mdx-blog-mdx-blog-post-jsx-content-file-path-src-blog-2024-microfrontends-architecture-overview-index-mdx" */),
  "component---src-components-templates-mdx-blog-mdx-blog-post-jsx-content-file-path-src-blog-2024-pydantic-vs-dataclasses-validators-index-mdx": () => import("./../../../src/components/templates/mdx-blog/mdx-blog-post.jsx?__contentFilePath=/opt/build/repo/src/blog/2024/pydantic-vs-dataclasses-validators/index.mdx" /* webpackChunkName: "component---src-components-templates-mdx-blog-mdx-blog-post-jsx-content-file-path-src-blog-2024-pydantic-vs-dataclasses-validators-index-mdx" */),
  "component---src-components-templates-mdx-blog-mdx-blog-post-jsx-content-file-path-src-blog-2024-seo-basics-in-react-index-mdx": () => import("./../../../src/components/templates/mdx-blog/mdx-blog-post.jsx?__contentFilePath=/opt/build/repo/src/blog/2024/seo-basics-in-react/index.mdx" /* webpackChunkName: "component---src-components-templates-mdx-blog-mdx-blog-post-jsx-content-file-path-src-blog-2024-seo-basics-in-react-index-mdx" */),
  "component---src-components-templates-mdx-blog-mdx-blog-post-jsx-content-file-path-src-blog-2024-strapi-101-index-mdx": () => import("./../../../src/components/templates/mdx-blog/mdx-blog-post.jsx?__contentFilePath=/opt/build/repo/src/blog/2024/strapi-101/index.mdx" /* webpackChunkName: "component---src-components-templates-mdx-blog-mdx-blog-post-jsx-content-file-path-src-blog-2024-strapi-101-index-mdx" */),
  "component---src-components-templates-mdx-blog-mdx-blog-post-jsx-content-file-path-src-blog-2024-typescript-upgrade-from-4-to-5-index-mdx": () => import("./../../../src/components/templates/mdx-blog/mdx-blog-post.jsx?__contentFilePath=/opt/build/repo/src/blog/2024/typescript-upgrade-from-4-to-5/index.mdx" /* webpackChunkName: "component---src-components-templates-mdx-blog-mdx-blog-post-jsx-content-file-path-src-blog-2024-typescript-upgrade-from-4-to-5-index-mdx" */),
  "component---src-components-templates-mdx-blog-mdx-blog-post-jsx-content-file-path-src-blog-2024-unlocking-the-power-of-the-has-selector-index-mdx": () => import("./../../../src/components/templates/mdx-blog/mdx-blog-post.jsx?__contentFilePath=/opt/build/repo/src/blog/2024/unlocking-the-power-of-the-has-selector/index.mdx" /* webpackChunkName: "component---src-components-templates-mdx-blog-mdx-blog-post-jsx-content-file-path-src-blog-2024-unlocking-the-power-of-the-has-selector-index-mdx" */),
  "component---src-components-templates-news-news-post-jsx": () => import("./../../../src/components/templates/news/news-post.jsx" /* webpackChunkName: "component---src-components-templates-news-news-post-jsx" */),
  "component---src-components-templates-team-member-team-member-jsx": () => import("./../../../src/components/templates/team-member/team-member.jsx" /* webpackChunkName: "component---src-components-templates-team-member-team-member-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-estimate-project-js": () => import("./../../../src/pages/estimate-project.js" /* webpackChunkName: "component---src-pages-estimate-project-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-portfolio-4-swiss-e-scooter-js": () => import("./../../../src/pages/portfolio/4swiss-e-scooter.js" /* webpackChunkName: "component---src-pages-portfolio-4-swiss-e-scooter-js" */),
  "component---src-pages-portfolio-charge-pilot-js": () => import("./../../../src/pages/portfolio/charge-pilot.js" /* webpackChunkName: "component---src-pages-portfolio-charge-pilot-js" */),
  "component---src-pages-portfolio-energy-market-js": () => import("./../../../src/pages/portfolio/energy-market.js" /* webpackChunkName: "component---src-pages-portfolio-energy-market-js" */),
  "component---src-pages-portfolio-home-garage-js": () => import("./../../../src/pages/portfolio/home-garage.js" /* webpackChunkName: "component---src-pages-portfolio-home-garage-js" */),
  "component---src-pages-portfolio-hr-tool-js": () => import("./../../../src/pages/portfolio/hr-tool.js" /* webpackChunkName: "component---src-pages-portfolio-hr-tool-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-portfolio-porto-santo-js": () => import("./../../../src/pages/portfolio/porto-santo.js" /* webpackChunkName: "component---src-pages-portfolio-porto-santo-js" */),
  "component---src-pages-portfolio-webshop-js": () => import("./../../../src/pages/portfolio/webshop.js" /* webpackChunkName: "component---src-pages-portfolio-webshop-js" */),
  "component---src-pages-services-e-commerce-js": () => import("./../../../src/pages/services/e-commerce.js" /* webpackChunkName: "component---src-pages-services-e-commerce-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-project-management-js": () => import("./../../../src/pages/services/project-management.js" /* webpackChunkName: "component---src-pages-services-project-management-js" */),
  "component---src-pages-services-web-development-js": () => import("./../../../src/pages/services/web-development.js" /* webpackChunkName: "component---src-pages-services-web-development-js" */)
}

